import React from "react";
import { Typography, CardMedia, CardActions, Button, Tooltip, Divider } from "@mui/material";
import { config } from "utils/config";
import AudioPlayer from "./Opciones/AudioPlayer";
import { showAlertImage } from "utils/alert";
// Icons
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

const server = config.API_RESOURCE_URL;

const LabelPreguntaRenderPregunta = (props, index) => {
    return <Typography
        variant="h6"
        gutterBottom
        sx={{ color: "primary.dark", fontSize: "15px" }}
    >
        {(props.index + 1)}. {props.nombre}
    </Typography>
}
const LabelRespuestaObtenidaRenderPregunta = () => {
    return <>
        <Divider />
        <Typography variant="subtitle2" component="div" sx={{ marginTop: "4px" }}>
            Respuesta del evaluado
        </Typography >
    </>
}

const ItemRenderPregunta = (props, index) => {
    switch (props.tipo) {
        case "texto":
            return (
                <>
                    <LabelRespuestaObtenidaRenderPregunta />
                    <Typography variant="h6" component="div">
                        {props.respuesta.nombre}
                    </Typography>
                </>
            );
        case "audio":
            return (
                <>
                    <LabelRespuestaObtenidaRenderPregunta />
                    <AudioPlayer
                        url={props.respuesta.file}
                    />
                    <Tooltip title="Respuesta generada por el sistema">
                        <Typography variant="h6" display="block" gutterBottom>
                            <QuestionAnswerIcon fontSize="small" sx={{ "p": 0, "m": 0, "marginRight": "6px" }} />
                            {props.respuesta.respuesta.automatica}
                        </Typography>
                    </Tooltip >
                </>
            );
        case "pizarra":
            return (
                <>
                    <LabelRespuestaObtenidaRenderPregunta />
                    <CardMedia
                        component="img"
                        src={props.respuesta.file}
                        alt="Imagen capturada"
                    />
                    {/* <CardActions
                        sx={{
                            p: 1,
                            m: 1,
                            bgcolor: "background.paper",
                            borderRadius: 1,
                        }}
                    >
                        <Button
                            size="small"
                            variant="contained"
                            onClick={showAlertImage({ "image": props.respuesta.file })}
                            fullWidth >
                            <ZoomOutMapIcon />
                            Expandir
                        </Button>
                    </CardActions > */}
                </>
            );
        case "input_numerico":
            return "";
        default:
            return "neutral";
    }
}

const CalificarRenderPregunta = (props, index) => {
    switch (props.tipo) {
        case "audio":
            return (
                <>
                    <LabelPreguntaRenderPregunta
                        index={props.index}
                        nombre={props.nombre}
                    />
                    <AudioPlayer
                        url={server + props.recurso}
                    />
                    <ItemRenderPregunta
                        tipo={props.respuesta.tipo}
                        respuesta={props.respuesta}
                    />
                </>
            );
        case "texto":
            return (
                <>
                    <LabelPreguntaRenderPregunta
                        index={props.index}
                        nombre={props.nombre}
                    />
                    <ItemRenderPregunta
                        tipo={props.respuesta.tipo}
                        respuesta={props.respuesta}
                    />
                </>
            );
        default:
            return "neutral";
    }
};
export default CalificarRenderPregunta;